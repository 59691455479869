<template>
  <div class="flex flex-wrap items-start justify-center gap-2.5 pt-10 pb-8 text-gray-100 border-t border-gray-300 lg:gap-0 lg:pt-16 lg:pb-16 lg:space-x-3 lg:flex-nowrap lg:justify-start">
    <span class="w-full mb-6 text-center lg:text-left lg:mr-auto lg:mb-0">Find us on socials:</span>
    <NuxtLink v-for="(link, i) in social"
              :key="i"
              class="box-border relative flex items-center justify-center flex-shrink-0 border border-white rounded-full w-14 h-14 group"
              target="_blank"
              rel="noreferrer"
              :to="link.url"
              @click="handleClick(link.title)"
    >
      <span class="sr-only">{{ link.title }}</span>
      <component :is="`Icon${link.icon}`"
                 class="h-5"
      />
      <span class="absolute transition-opacity duration-200 rounded-full opacity-0 inset-[-1px] icon-container mix-blend-multiply group-hover:opacity-100" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import social from '../../content/navigation/social.json'
import { useNuxtApp } from '#imports'

const { $event } = useNuxtApp()

function handleClick(linkTitle: string) {
  $event('Link', { props: { Footer: linkTitle } })
}
</script>
